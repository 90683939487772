export const materialData1 = [
	{
		Material: "201, 304, 304l, 316, 316l, or as customer's request",
	},
	{
		Standard: "ASTM, AISI, GB, JIS ,customers' requests available",
	},
	{
		Size: "Round Tube: OD: 16-119mm or customized",
	},
	{
		Thickness: "0.25mm-3.0mm",
	},
	{
		Length: "6m or according to customers' request",
	},
	{
		Sample: "Free sample is available, but freight should be paid by you",
	},
	{
		Tolerance: [
			"Outer diameter : ±0.2mm",
			"Thickness : ±0.05mm",
			"Length : ±0.5mm",
		],
	},
	{
		Ends: "In plain end or bevelled ends or as your requirements",
	},
	{
		"Welding Line Type": "ERW (Electrical Resistance Weld)",
	},
	{
		Finishing: [
			"A. Sanded",
			"B. Gloss #400, Mirror #600 - #1000",
			"C. Brushed",
			"D. Wood Grain",
			"E. Embossed, Threaded",
		],
	},
	{
		Application: "Decorations, constructions, food industry, etc.",
	},
	{
		Certification: "ISO, SGS",
	},
];

export const materialData2 = [
	{
		Material: [
			"304:0cr18ni9,0cr17ni8",
			"201:1cr17mn6ni5n,1cr13mn9ni1n",
			"316:0cr17ni12 or as customer’s request",
		],
	},
	{
		"Size (SS round pipe)":
			"9.5, 12.7, 15.9, 17, 18, 19.1, 20, 21, 22.2, 23, 25.4, 28, 318, 35, 60, 63.5, 73, 76.2, 88.9, 101.6, 114.3, 127, 133, 159, 168, 219 mm or customized",
	},
	{
		"Size (SS square pipe)":
			"10×10, 12×12, 18×18, 23×23, 38×38, 50×50, 70×70, 100×100, 20×10, 23×10, 24×12, 25×13, 36×23, 40×20, 50×20, 60×15, 75×45, 80×60, 95×45, 100×60, 150×100 mm or customized",
	},
	{
		"Size (SS oval pipe)": "30×15, 80×40, mm or customized",
	},
	{
		Thickness: "0.24-3 mm",
	},
	{
		Length: "6m or according to customer’s request",
	},
	{
		"Outer diameter": "6.35-219mm",
	},
	{
		Tolerance: [
			"Outer diameter:±0.2mm",
			"Thickness:±0.02mm",
			"Length:±0.5mm",
		],
	},
	{
		"Process method":
			"Code drawn, annealed with nitrogen protection, ultrasonic, automatic shape, polished",
	},
	{
		Finishing: [
			"A: sanded",
			"B: 400#-600# mirror",
			"C: hairline brushed",
			"D: TIN titanlum",
			"E : HL brushed & mirror (two kinds of finishing for one pipe)",
		],
	},
];

export const materialData3 = [
	{
		Standard: [
			"ASTM A312",
			"ASTM A213",
			"ASTM A269",
			"ASTM A511",
			"ASTM A789",
			"ASTM A790",
			"JIS3463",
			"JIS3459",
			"DIN2462",
			"DIN17458",
			"DIN17456",
		],
	},
	{
		Grade: [
			"304",
			"304L",
			"321",
			"321H",
			"310S",
			"347H",
			"2205",
			"316",
			"316L",
			"316Ti",
			"317L",
		],
	},
	{
		"OD range": "6mm-720mm",
	},
	{
		"Wall thickness range": "0.5-60mm",
	},
	{
		Length: "max.18000mm or as demand",
	},
	{
		"Surface finish": [
			"Polishing (180#, 220#, 240#, 320#, 400#, 600#)",
			"Hairline",
			"acid pickled",
		],
	},
];

export const materialData4 = [
	{
		StainlessSteel: "StainlessSteel",
		Tradename: "Trade Name",
		UNSNo: "UNS No.",
		WerkstoffNo: "Werkstoff No.",
		Density: "Density (g/m^3)",
	},
	{
		StainlessSteel: "304",
		Tradename: "304 Stainless",
		UNSNo: "S30400",
		WerkstoffNo: 1.4301,
		Density: 8,
	},
	{
		StainlessSteel: "304L",
		Tradename: "304L Stainless",
		UNSNo: "S30403",
		WerkstoffNo: 1.4307,
		Density: 8,
	},
	{
		StainlessSteel: "304H",
		Tradename: "304H Stainless",
		UNSNo: "S30409",
		WerkstoffNo: 1.4948,
		Density: 8,
	},
	{
		StainlessSteel: "304LN",
		Tradename: "304LN Stainless",
		UNSNo: "S30453",
		WerkstoffNo: 1.4311,
		Density: 8,
	},
];

export const materialData5 = [
	{
		Grade: " ",
		C: "C",
		Mn: "Mn",
		Si: "Si",
		P: "P",
		S: "S",
		Cr: "Cr",
		Ni: "Ni",
		Fe: "Fe",
	},
	{
		Grade: "304",
		C: "0.08 max.",
		Mn: "2.00 max.",
		Si: "1.00 max.",
		P: "0.045 max.",
		S: "0.03 max.",
		Cr: "18.0 – 20.0",
		Ni: "8.0 – 10.5",
		Fe: "Balance",
	},
	{
		Grade: "304L",
		C: "0.03 max.",
		Mn: "2.00 max.",
		Si: "1.00 max.",
		P: "0.045 max.",
		S: "0.03 max.",
		Cr: "18.0 – 20.0",
		Ni: "8.0 – 12.0",
		Fe: "Balance",
	},
	{
		Grade: "304H",
		C: "0.04-0.10",
		Mn: "2.00 max.",
		Si: "1.00 max.",
		P: "0.045 max.",
		S: "0.03 max.",
		Cr: "18.0 – 20.0",
		Ni: "8.0 – 12.0",
		Fe: "Balance",
	},
];

export const materialData6 = [
	{
		Property: " ",
		"304 ": 304,
		"304L": "304L",
		"304H": "304H   ",
	},
	{
		Property: "Density",
		"304 ": 8,
		"304L": 8,
		"304H": 8,
	},
	{
		Property: "Melting Range (℃)",
		"304 ": "1400 ℃",
		"304L": "1400 ℃",
		"304H": "1400 ℃",
	},
	{
		Property: "Elongation %",
		"304 ": 40,
		"304L": 40,
		"304H": 40,
	},
	{
		Property: "Tensile Strength (Mpa)",
		"304 ": 515,
		"304L": 485,
		"304H": 515,
	},
	{
		Property: "Yield Strength (Mpa)",
		"304 ": 205,
		"304L": 170,
		"304H": 205,
	},
	{
		Property: "Hardness (Brinell)",
		"304 ": 201,
		"304L": 201,
		"304H": 201,
	},
];

export const materialData7 = [
	{
		"Inches ": "Inches",
		"mm ": "mm",
		"Qty/B": "Lgh",
		"0.6 ": "Kgs",
		"0.7 ": "Kgs",
		"0.8 ": "Kgs",
		"0.9 ": "Kgs",
		"1.0 ": "Kgs",
		"1.2 ": "Kgs",
		"1.5 ": "Kgs",
		"2.0 ": "Kgs",
		"3.0 ": "Kgs",
		"4.0 ": "Kgs",
		"5.0 ": "Kgs",
		"6.0 ": "Kgs",
	},
	{
		"Inches ": "3/8",
		"mm ": 9.5,
		"Qty/B": 100,
		"0.6 ": 0.81,
		"0.7 ": 0.94,
		"0.8 ": 1.06,
		"0.9 ": 1.18,
		"1.0 ": 1.29,
		"1.2 ": 1.51,
		"1.5 ": 1.82,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "1/2",
		"mm ": 12.7,
		"Qty/B": 100,
		"0.6 ": 1.1,
		"0.7 ": 1.28,
		"0.8 ": 1.45,
		"0.9 ": 1.61,
		"1.0 ": 1.78,
		"1.2 ": 2.1,
		"1.5 ": 2.55,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "5/8",
		"mm ": 15.9,
		"Qty/B": 100,
		"0.6 ": 1.39,
		"0.7 ": 1.62,
		"0.8 ": 1.84,
		"0.9 ": 2.05,
		"1.0 ": 2.26,
		"1.2 ": 2.68,
		"1.5 ": 3.28,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "3/4",
		"mm ": 19.1,
		"Qty/B": 100,
		"0.6 ": 1.69,
		"0.7 ": 1.96,
		"0.8 ": 2.22,
		"0.9 ": 2.49,
		"1.0 ": 2.75,
		"1.2 ": 3.26,
		"1.5 ": 4.01,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "7/8",
		"mm ": 22.2,
		"Qty/B": 100,
		"0.6 ": 1.97,
		"0.7 ": 2.29,
		"0.8 ": 2.6,
		"0.9 ": 2.91,
		"1.0 ": 3.22,
		"1.2 ": 3.83,
		"1.5 ": 4.72,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "1",
		"mm ": 25.4,
		"Qty/B": 100,
		"0.6 ": 2.26,
		"0.7 ": 2.63,
		"0.8 ": 2.99,
		"0.9 ": 3.35,
		"1.0 ": 3.71,
		"1.2 ": 4.41,
		"1.5 ": 5.45,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "11/8",
		"mm ": 28.6,
		"Qty/B": 50,
		"0.6 ": 2.55,
		"0.7 ": 2.97,
		"0.8 ": 3.38,
		"0.9 ": 3.79,
		"1.0 ": 4.19,
		"1.2 ": 5.0,
		"1.5 ": 6.18,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "11/4",
		"mm ": 31.8,
		"Qty/B": 50,
		"0.6 ": 2.84,
		"0.7 ": 3.31,
		"0.8 ": 3.77,
		"0.9 ": 4.23,
		"1.0 ": 4.68,
		"1.2 ": 5.58,
		"1.5 ": 6.91,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "11/2",
		"mm ": 38.1,
		"Qty/B": 50,
		"0.6 ": 3.42,
		"0.7 ": 3.98,
		"0.8 ": 4.53,
		"0.9 ": 5.09,
		"1.0 ": 5.64,
		"1.2 ": 6.73,
		"1.5 ": 8.34,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "15/8",
		"mm ": 41.3,
		"Qty/B": 50,
		"0.6 ": 3.71,
		"0.7 ": 4.32,
		"0.8 ": 4.92,
		"0.9 ": 5.52,
		"1.0 ": 6.12,
		"1.2 ": 7.31,
		"1.5 ": 9.07,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "13/4",
		"mm ": 44.5,
		"Qty/B": 50,
		"0.6 ": 4.0,
		"0.7 ": 4.66,
		"0.8 ": 5.31,
		"0.9 ": 5.96,
		"1.0 ": 6.61,
		"1.2 ": 7.9,
		"1.5 ": 9.8,
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "2",
		"mm ": 50.8,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": 6.08,
		"0.9 ": 6.82,
		"1.0 ": 7.57,
		"1.2 ": 9.04,
		"1.5 ": 11.24,
		"2.0 ": 14.83,
		"3.0 ": 21.79,
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "21/2",
		"mm ": 63.5,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": 7.62,
		"0.9 ": 8.56,
		"1.0 ": 9.5,
		"1.2 ": 11.36,
		"1.5 ": 14.13,
		"2.0 ": 18.69,
		"3.0 ": 27.58,
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "3",
		"mm ": 76.2,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": 9.17,
		"0.9 ": 10.3,
		"1.0 ": 11.43,
		"1.2 ": 13.68,
		"1.5 ": 17.03,
		"2.0 ": 22.55,
		"3.0 ": 33.37,
		"4.0 ": 43.88,
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "31/2",
		"mm ": 88.9,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": 10.71,
		"0.9 ": 12.03,
		"1.0 ": 13.36,
		"1.2 ": 15.99,
		"1.5 ": 19.92,
		"2.0 ": 26.41,
		"3.0 ": 39.16,
		"4.0 ": 51.6,
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "4",
		"mm ": 101.6,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": " ",
		"0.9 ": " ",
		"1.0 ": " ",
		"1.2 ": 18.31,
		"1.5 ": 22.82,
		"2.0 ": 30.27,
		"3.0 ": 44.95,
		"4.0 ": 59.32,
		"5.0 ": 73.39,
		"6.0 ": 87.16,
	},
	{
		"Inches ": "5",
		"mm ": 127,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": " ",
		"0.9 ": " ",
		"1.0 ": " ",
		"1.2 ": " ",
		"1.5 ": " ",
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
	{
		"Inches ": "6",
		"mm ": 152.4,
		"Qty/B": 10,
		"0.6 ": " ",
		"0.7 ": " ",
		"0.8 ": " ",
		"0.9 ": " ",
		"1.0 ": " ",
		"1.2 ": " ",
		"1.5 ": " ",
		"2.0 ": " ",
		"3.0 ": " ",
		"4.0 ": " ",
		"5.0 ": " ",
		"6.0 ": " ",
	},
];
